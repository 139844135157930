import React from "react"

import Layout from "../components/layout"

const ProjectsPage = () => (
    <Layout>
        <h1>Projects</h1>
        <p>Coming Soon</p>
    </Layout>
)

export default ProjectsPage;